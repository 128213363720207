// export default function accordion() {
const trigger = '.js-trigger-accordion';
const activeClass = 'is-active';

$(document).on('click', trigger, function () {
    const $target = $(this).parent('.js-target-accordion');
    if ($target.hasClass(activeClass)) {
        $(this).removeClass(activeClass);
        $target.removeClass(activeClass);
    } else {
        $(this).addClass(activeClass);
        $target.addClass(activeClass);
    }
});

const collapseTrigger = '.js-trigger-collapse';
$(document).on('click', collapseTrigger, function () {
    const target = $(this).attr('data-target');
    const show = $(this).attr('data-show');
    const hide = $(this).attr('data-hide');
    if ($(target).hasClass(activeClass)) {
        $(target).removeClass(activeClass);
        $(this).text(show);
    } else {
        $(target).addClass(activeClass);
        $(this).text(hide);
    }
});
// }
